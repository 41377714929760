import { getFeaturesDTO } from './Configs.dto'

export function getFeaturesMapper (data: { [zone: string]: any }): getFeaturesDTO {
  const features = Object.entries(data) as [string, any][]
  return features.reduce((prev, [zone, features]) => {
    return {
      ...prev,
      [zone]: {
        accountServiceVersion: features.accountServiceVersion,
        upsertDeliveryCenterEnabled: features.upsertDeliveryCenterEnabled,
        categoryServiceVersion: features.categoryServiceVersion,
        itemServicesVersion: features.itemServicesVersion,
        associateProductsToCategoryEnabled: features.associateProductsToCategoryEnabled,
        inputInventoryServiceConfig: {
          service: features.inputInventoryServiceConfig?.service,
          version: features.inputInventoryServiceConfig?.version
        },
        priceDepositEnabled: features.priceDepositEnabled,
        priceValidFromEnabled: features.priceValidFromEnabled,
        priceRelayVersion: features.priceDepositEnabled,
        productAssortmentRelayVersion: features.productAssortmentRelayVersion,
        paymentMethods: features.paymentMethods,
        chargesFeeTypes: features.chargesFeeTypes,
        deliveryWindowTypes: features.deliveryWindowTypes,
        hasPerishables: features.hasPerishables,
        productCountMethods: features.productCountMethods,
        stockControlType: features.stockControlType,
        hasEmpties: features.hasEmpties,
        emptiesType: features.emptiesType,
        vendorBehavior: features.vendorBehavior,
        deliveryCenters: features.deliveryCenters,
        comboTypes: features.comboTypes,
        dealTypes: features.dealTypes,
        deliveryMethods: features.deliveryMethods,
        liquorLicenseEnabled: features.liquorLicenseEnabled,
        pickupOrderEnabled: features.pickupOrderEnabled,
        hasItemVariants: features.hasItemVariants,
        pricePtcEnabled: features.pricePtcEnabled,
        customerAccountIdEnabled: features.customerAccountIdEnabled,
        hasDealFreeGoodMinimumAmountEnabled: features.hasDealFreeGoodMinimumAmountEnabled,
        recommendationTypes: features.recommendationTypes,
        hasPromotionScore: features.hasPromotionScore,
        hasWishlist: features.hasWishlist,
        hasShoppingList: features.hasShoppingList,
        itemDefaultLanguage: features.itemDefaultLanguage,
        translationLanguages: features.translationLanguages,
        hasDigitalCoupons: features.hasDigitalCoupons,
        pickupQuantityEnabled: features.pickupQuantityEnabled,
        subClientsEnabled: features.subClientsEnabled,
        hasQuantityMultiplierEnabled: features.hasQuantityMultiplierEnabled,
        storeSelectorEnabled: features.storeSelectorEnabled,
        hasCreditPaymentTerm: features.hasCreditPaymentTerm,
        metadataAccountsReceivableEnabled: features.metadataAccountsReceivableEnabled,
        hasSolarTermEnabled: features.hasSolarTermEnabled,
        minimumOrderTypes: features.minimumOrderTypes,
        maximumOrderTypes: features.maximumOrderTypes,
        poNumberRequirementEnabled: features.poNumberRequirementEnabled,
        taxIdType: features.taxIdType
      } as getFeaturesDTO[0]
    }
  }, {})
}
